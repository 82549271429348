import { AppProps } from 'next/app';

import { Source_Sans_3 } from 'next/font/google';
import './styles.css';

import { QueryClient, QueryClientProvider, Hydrate } from '@tanstack/react-query';
import { SessionProvider } from 'next-auth/react';

import { install } from 'resize-observer';

import { RootLayout } from '@sobat-bangun/ui/layouts';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';

const source = Source_Sans_3({
  subsets: ['latin'],
  weight: ['200', '300', '400', '600', '700', '900'],
  style: ['normal', 'italic'],
  variable: '--font-source',
});

// const queryClient = new QueryClient();

function CustomApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());

  // Polyfill ResizeObserver on un supported browser e.g. (safari 12)
  useEffect(() => {
    if (!window.ResizeObserver) install();
  }, []);

  return (
    <>
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>Sobat Bangun</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=yes, viewport-fit=cover" />
        <meta name="title" content="Sobat Bangun"></meta>
        <meta
          name="description"
          content="SobatBangun adalah Penyedia Jasa Renovasi Rumah Profesional yang dapat Mentransformasikan rumah impian Anda dengan tim ahli kami."
        />
        <meta
          name="keywords"
          content="Jasa renovasi rumah, Desain interior rumah, Bahan bangunan, Tukang bangunan, Desain eksterior rumah, Renovasi kamar mandi, Jasa kontraktor bangunan, Renovasi interior rumah, Renovasi eksterior rumah, Ide renovasi rumah, Kontraktor renovasi rumah, Biaya renovasi rumah, Desain renovasi rumah, Material renovasi rumah, Proyek renovasi rumah, Perencanaan renovasi rumah, Jasa tukang bangunan, Kontraktor pembangunan rumah, Desain rumah, Proses pembangunan rumah, Biaya pembangunan rumah, Material bangunan, Izin pembangunan rumah, Desainer arsitek, Ide desain rumah, Desain interior rumah, Furniture rumah, Dekorasi rumah, Pencahayaan interior, Pemilihan warna, Penataan ruang, Desain kamar tidur, Material dinding interior, Taman minimalis, Jasa arsitek, Proyek DIY rumah, Desain dapur modern, Rumah pasif, Rumah minimalis, Toko peralatan konstruksi, Pemeliharaan rumah"
        />
        {/* Prefetch 3rd party url */}
        <link rel="preconnect" href="https://i.ytimg.com" />
        <link rel="preconnect" href={process.env.NX_SENTRY_DSN} />

        <link rel="icon" type="image/png" sizes="32x32" href="/favicon.svg" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon.svg" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/favicon.svg" color="#5bbad5" />
        <link rel="shortcut icon" href="/favicon.svg" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="Website" />
        <meta property="og:url" content={process.env.NEXT_PUBLIC_HOST || `https://www.sobatbangun.com/`} />
        <meta property="og:title" content="Sobat Bangun" />
        <meta
          property="og:description"
          content="SobatBangun adalah Penyedia Jasa Renovasi Rumah Profesional yang dapat Mentransformasikan rumah impian Anda dengan tim ahli kami."
        />
        <meta property="og:image" itemProp="image" content={process.env.NEXT_PUBLIC_HOST + `/images/sobat-bangun.png`} />
        <meta property="og:image:alt" itemProp="image" content={process.env.NEXT_PUBLIC_HOST + `/images/sobat-bangun.png`} />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={process.env.NEXT_PUBLIC_HOST || `https://www.sobatbangun.com/`} />
        <meta property="twitter:title" content="Sobat Bangun" />
        <meta
          property="twitter:description"
          content="SobatBangun adalah Penyedia Jasa Renovasi Rumah Profesional yang dapat Mentransformasikan rumah impian Anda dengan tim ahli kami."
        />
        <meta property="twitter:image" content={process.env.NEXT_PUBLIC_HOST + `/images/sobat-bangun.png`} />
      </Head>
      <script src="//code.tidio.co/6n2fvm1tgyiftvdp9k3tyod6mmwsyhgx.js" async></script>
      <Script id="google-analytics-js" strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} />
      <Script id="google-analytics-init" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
        `}
      </Script>
      <style jsx global>{`
        :root {
          --font-source: ${source.style.fontFamily};
        }
      `}</style>
      <SessionProvider refetchWhenOffline={false} session={session}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <RootLayout>
              <main className="overflow-x-hidden font-sans">
                <Component {...pageProps} />
              </main>
            </RootLayout>
          </Hydrate>
        </QueryClientProvider>
      </SessionProvider>
    </>
  );
}

export default CustomApp;
