import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';

const config: AxiosRequestConfig = {
  baseURL: `${process.env.NEXT_PUBLIC_HOST}/api`,
  withCredentials: true,
};

const configAPI: AxiosRequestConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_URL,
};

const configAPIFormData: AxiosRequestConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const configApiStrapi: AxiosRequestConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_STRAPI_URL,
  headers: {
    Authorization: 'Bearer ' + process.env.NEXT_PUBLIC_STRAPI_TOKEN,
  },
};

const configApiThirdParty: AxiosRequestConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_STRAPI_THIRD_PARTY_URL,
};

export const apiBackendFormData = axios.create(configAPIFormData);

export const apiBackend = axios.create(configAPI);

export const apiStrapi = axios.create(configApiStrapi);

export const api = axios.create(config);

export const apiNotification = axios.create(configAPI);

export const apiThirdParty = axios.create(configApiThirdParty);
