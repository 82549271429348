var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"zfTLT4Y8lbcX6mXCddX6U"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { BrowserTracing, Replay, init, onLoad } from '@sentry/nextjs';

const sentryDsn = process.env.NX_SENTRY_DSN || process.env.NX_NEXT_PUBLIC_SENTRY_DSN;

if (sentryDsn) {
  onLoad(async () => {
    init({
      dsn: sentryDsn,
      integrations: [
        new BrowserTracing(),
        process.env.NX_SENTRY_REPLAY_PRIVATE === 'true' &&
          new Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
          }),
      ],

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 1.0,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: +process.env.TRACES_SAMPLE_RATE || 1.0,
    });
  });
}
