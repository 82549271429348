import { api, apiBackend, apiBackendFormData, apiNotification, apiStrapi, apiThirdParty } from '@sobat-bangun/services';
import { useSession } from 'next-auth/react';
import { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { setUser } from '@sentry/nextjs';

export interface IRootLayoutProps {
  children: ReactNode;
}

export const RootLayout: FC<IRootLayoutProps> = ({ children }) => {
  const session = useSession();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (session.data?.user?.email) setUser({ email: session.data?.user?.email });
    else setUser(null);
  }, [session.data?.user?.email]);

  useEffect(() => {
    // Request Interceptor
    apiBackend.interceptors.request.use(
      (config) => {
        const token = session.data?.access_token;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    apiBackendFormData.interceptors.request.use(
      (config) => {
        const token = session.data?.access_token;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    apiStrapi.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${process.env.NEXT_PUBLIC_STRAPI_TOKEN}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    apiThirdParty.interceptors.request.use(
      async (config) => {
        config.headers['X-API-KEY'] = process.env.NEXT_PUBLIC_STRAPI_THIRD_PARTY_X_API_KEY;
        return config;
      },
      (error) => Promise.reject(error)
    );

    api.interceptors.request.use(
      (config) => {
        const token = session.data?.access_token;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    apiNotification.interceptors.request.use(
      (config) => {
        const userId = session.data?.user.id;
        if (userId) {
          config.headers.Authorization = userId;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // wait session data if user has authenticated
    if (session.data || session.status === 'unauthenticated') setReady(true);
  }, [session.data]);

  return <Fragment>{ready ? children : null}</Fragment>;
};
